// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-doencas-js": () => import("./../../../src/pages/doencas.js" /* webpackChunkName: "component---src-pages-doencas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obrigado-js": () => import("./../../../src/pages/obrigado.js" /* webpackChunkName: "component---src-pages-obrigado-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-tratamentos-js": () => import("./../../../src/pages/tratamentos.js" /* webpackChunkName: "component---src-pages-tratamentos-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-diseases-post-js": () => import("./../../../src/templates/diseases-post.js" /* webpackChunkName: "component---src-templates-diseases-post-js" */),
  "component---src-templates-img-post-js": () => import("./../../../src/templates/img-post.js" /* webpackChunkName: "component---src-templates-img-post-js" */),
  "component---src-templates-treatments-post-js": () => import("./../../../src/templates/treatments-post.js" /* webpackChunkName: "component---src-templates-treatments-post-js" */)
}

